
import {
  mdiEmail,
  mdiPhone,
  mdiMapSearchOutline,
  mdiHome,
  mdiClock,
  mdiMenu,
  mdiOfficeBuilding,
  mdiTrendingUp,
  mdiHumanHandsup,
  mdiUmbrella,
  mdiAccountGroup,
  mdiNewspaperVariantOutline,
  mdiChat,
  mdiFacebook,
  mdiLinkedin,
} from "@mdi/js";

export default {
  data() {
    return {
      /* nav */
      mdiEmail: mdiEmail,
      mdiPhone: mdiPhone,
      mdiMapSearchOutline: mdiMapSearchOutline,
      mdiClock: mdiClock,
      mdiHome: mdiHome,
      mdiMenu: mdiMenu,
      mdiOfficeBuilding: mdiOfficeBuilding,
      mdiTrendingUp: mdiTrendingUp,
      mdiHumanHandsup: mdiHumanHandsup,
      mdiUmbrella: mdiUmbrella,
      mdiAccountGroup: mdiAccountGroup,
      mdiNewspaperVariantOutline: mdiNewspaperVariantOutline,
      mdiChat: mdiChat,
      mdiFacebook: mdiFacebook,
      mdiLinkedin: mdiLinkedin,

      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: mdiOfficeBuilding,
          title: "Home",
          to: "/",
        },
        {
          icon: mdiHome,
          title: "Mortgages",
          to: "/mortgages",
        },
        {
          icon: mdiTrendingUp,
          title: "Investments",
          to: "/investments",
        },
        {
          icon: mdiHumanHandsup,
          title: "Pensions",
          to: "/pensions",
        },
        {
          icon: mdiUmbrella,
          title: "Protection",
          to: "/protection",
        },
        {
          icon: mdiAccountGroup,
          title: "About",
          to: "/about",
        },
        {
          icon: mdiNewspaperVariantOutline,
          title: "News",
          to: "/news",
        },
        {
          icon: mdiChat,
          title: "Contact Us",
          to: "/contact-us",
        },
      ],
      icons: [
        {
          icon: mdiFacebook,
          aria: "Facebook",
          url: "https://www.facebook.com/the.centre.court.partnership/",
        },
        {
          icon: mdiLinkedin,
          aria: "Linkedin",
          url: "https://www.linkedin.com/company/the-centre-court-partnership/",
        },
      ],
      footerLinks: [
        {
          name: "Terms & Conditions",
          url: "/terms-and-conditions",
        },
        {
          name: "Privacy Policy",
          url: "/privacy-policy",
        },
      ],
    };
  },
};
